import { type IconProps, Icon } from './icon.js';

export function Microphone(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Microphone" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M9.63494 18.5368c0-.5441.43936-.9853.98136-.9853s.9814.4412.9814.9853h1.9627c.542 0 .9813.4411.9813.9853V21H6.69087v-1.4779c0-.5442.43937-.9853.98136-.9853h1.96271ZM13.3715 3.18225c2.659.70981 4.2849 3.28714 3.6311 5.75665l-1.2458 4.7067c-.6536 2.4695-3.3394 3.896-5.99835 3.1862-2.12083-.5661-3.58427-2.32-3.75845-4.2597l2.69732.7227c.44307.1187.8985-.1442 1.01712-.5873.11883-.4431-.14417-.8985-.58723-1.0172l-2.95343-.7914.42502-1.60588 2.95849.79268c.44311.1187.89851-.14422 1.01711-.5873.1188-.44309-.1442-.89851-.58722-1.01723l-2.96316-.794.34897-1.31873c.02718-.10272.058-.20364.09206-.30267l2.81355.75387c.443.11872.8985-.14423 1.0171-.58731.1188-.44307-.1442-.89851-.5872-1.01723l-2.36908-.63473c1.17225-1.30756 3.10918-1.91142 5.03208-1.39812Z" />
      </svg>
    </Icon>
  );
}
